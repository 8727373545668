import 'antd/dist/antd.min.css';
import Preview from './feature/preview/preview';
import './App.css';
import { VersionPath } from './config/path';
console.log('VersionPath', VersionPath);



function App() {
  return (
    <div className="App">
         <Preview></Preview>    
    </div>
  );
}

export default App;
